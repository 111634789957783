import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import axios from "axios";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import IssoComments from "../components/IssoComments";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { v4 } from "uuid";

const ENDPOINT = "https://mensindezorg.nl/api/reactions";

const USER_ID_KEY = "userId";
const getUserId = () =>
  typeof window !== "undefined" && localStorage.getItem(USER_ID_KEY);
const setLocalUserId = userId => localStorage.setItem(USER_ID_KEY, userId);

const Podcast = ({ data, isPreview }) => {
  const { frontmatter } = data.markdownRemark;
  const hashtags = data.markdownRemark.frontmatter.hashtags
    .replace(/ /g, "")
    .replace(/,/g, "")
    .replace(/;/g, "")
    .split("#")
    .splice(1);
  const [reactions, setReactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReacted, setIsReacted] = useState(false);
  const [userId, setUserId] = useState(getUserId());
  const id = `S${frontmatter.season}E${frontmatter.episode}`;

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get(ENDPOINT, {
        params: { postId: id },
      });
      setIsReacted(
        !!response.data.reactions.find(item => item.user_id === userId)
      );
      setReactions(response.data.reactions);
      setIsLoading(false);
    };
    fetch();

    if (!userId) {
      const newUserId = v4();
      setUserId(newUserId);
      setLocalUserId(newUserId);
    }
  }, []);

  const createReaction = async () => {
    try {
      setIsLoading(true);
      const reaction = { userId, postId: id };
      await axios.post(ENDPOINT, reaction);
      setReactions([...reactions, { user_id: userId, post_id: id }]);
      setIsReacted(true);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const deleteReaction = async () => {
    try {
      setIsLoading(true);
      const reaction = { userId, postId: id };
      await axios.post(`${ENDPOINT}/delete`, reaction);
      console.log({ reactions, userId });
      setReactions(reactions.filter(item => item.user_id !== userId));
      setIsReacted(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Layout title={frontmatter.title}>
      {!isPreview && <SEO title={frontmatter.title} />}
      <div className="single-pod-page">
        <div className="banner"></div>
        <div className="container-lg">
          <div className="title">
            <img alt="" loading="lazy" src="/uploads/decoration.png"></img>
            <h2>{frontmatter.title}</h2>
            <h6>
              Season {frontmatter.season} Episode {frontmatter.episode}
            </h6>
          </div>
          <blockquote>
            <p className="text">{frontmatter.text}</p>
          </blockquote>
          <div className="hashtag-wrapper">
            {hashtags.map((hashtag, index) => (
              <div key={index} className="hashtag">
                #{hashtag}
              </div>
            ))}
          </div>
          <div className="listen">
            <h6>nu luisteren!</h6>
            <AudioPlayer src={frontmatter.custom_upload} />
            <div className="music-producer">{frontmatter.music_producer}</div>
          </div>
          <div className="listen-like-section">
            Give it a like!
            <button
              style={{ cursor: "pointer", opacity: isLoading ? 0.5 : 1 }}
              disabled={isLoading}
              onClick={() => {
                if (isReacted) {
                  deleteReaction();
                  return;
                }

                createReaction();
              }}
            >
              <span>{reactions.length}</span>
              <span>{isReacted ? <AiFillHeart /> : <AiOutlineHeart />}</span>
            </button>
          </div>
          <div className="comments">
            <h4>Laat een commentaar achter!</h4>
            <div className="write-comment">
              <div className="row">
                <div className="col">
                  <IssoComments id={id} />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <label htmlFor="name-label" className="form-label">
                    Naam
                  </label>
                  <input
                    className="form-control"
                    id="name-label"
                    aria-describedby="name-label"
                    required
                  ></input>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label htmlFor="email-label" className="form-label">
                    E-mailadres
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email-label"
                    aria-describedby="email-label"
                  ></input>
                </div>
                <div className="col-12 mb-4">
                  <label htmlFor="name-label" className="form-label">
                    Commentaar
                  </label>
                  <textarea
                    type="textbox"
                    className="form-control"
                    id="name-label"
                    aria-describedby="name-label"
                    required
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="aheto-btn js-video-btn aheto-btn--primary aheto-btn--large submit-comment"
              >
                Schrijven!
              </button> */}
              {/* If you want to align the styling of Isso comments more go ahead and use this as an example */}
              {/* <div className="show-comments">
                <div className="tr-spacer">-//-</div>
                <div className="indi-comment">
                  <div className="com-name">
                    —&nbsp;&nbsp;Julia<div>( julia@domain.com )</div>
                  </div>
                  <div className="com-text">
                    „There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.“
                  </div>
                  <div className="com-spacer"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Podcast;

export const podcastQuery = graphql`
  query PodcastTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fileAbsolutePath
      frontmatter {
        title
        text
        episode
        season
        custom_upload
        hashtags
        music_producer
      }
    }
  }
`;
